import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  createFilterOptions,
  TextField,
  Autocomplete,
  Button,
  Collapse,
  IconButton,
  Box,
  Dialog,
  Divider, DialogTitle, DialogContent, DialogContentText, List, ListItem, ListItemText, DialogActions,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Chart from "react-apexcharts";
import moment from "moment";
import { masterAPI } from "../Masters/dataConfig";
import { axiosPrivate } from "../../axios/axios";
import Loading from "../../Component/Loading";
import { color } from "../../StyleData/Config";
import MTBFChart from "./MTBFChart";
import MTTRChart from "./MTTR";
import PMComplChart from "./PMComplChart";
import FailureClassChart from "./FailureClassChart";
import ProblemChart from "./ProblemChart";
import CausesChart from "./CausesChart";
import RemedyChart from "./RemedyChart";
import OpenWoList from "./OpenWoList";
import OverDueWoList from "./OverDueWoList";
import CostWOList from "./CostWOList";
import ServiceWOList from "./ServiceWOList";

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [dashBoardDataXaxis, setDashBoardDataXaxis] = useState([]);
  const [dashBoardDataYaxis, setDashBoardDataYaxis] = useState([]);
  const [dashBoardDatamttXaxis, setDashBoardDatamttXaxis] = useState([]);
  const [dashBoardDatamttYaxis, setDashBoardDatamttYaxis] = useState([]);
  const [dashBoardDataComplaianceXaxis, setDashBoardDataComplaianceXaxis] = useState([]);
  const [dashBoardDataComplaianceYaxis, setDashBoardDataComplaianceYaxis] = useState([]);
  const [failureData,setFailureData]=useState({
    xAxis:[],
    yAxis:[]
  })
  const [problemData,setProblemData]=useState({
    xAxis:[],
    yAxis:[]
  })
  const [causesData,setCausesData]=useState({
    xAxis:[],
    yAxis:[]
  })
  const [remedyData,setRemedyData]=useState({
    xAxis:[],
    yAxis:[]
  })
  const [getDateFrom, setGetDateFrom] = useState(moment().subtract(3, "months").format("YYYY-MM-DDTHH:mm:ss"));
  const [getDateTo, setGetDateTo] = useState(moment().format("YYYY-MM-DDTHH:mm:ss"));
  const [isLoading, setIsLoading] = useState(false);
  const [selectCatValue, setSelectCatValue] = useState({
    id:null,
    text:""
  });
  const [assetCat, setAssetCat] = useState([]);
  const [selectTypeValue, setSelectTypeValue] = useState({
    id: null,
    text: "",
  });
  const [assetType, setAssetType] = useState([]);
  const [location, setLocation] = useState({
    id: null,
    text: "",
  });
  const [locationData, setLocationData] = useState([]);
  const [wocountData, setWOCountData] = useState({
    woOpen: 0,
    backLog: 0,
  });
  const [srCountData, setSRCountData] = useState({
    srNew: 0,
  });
  const [costandLab, setCostAndLab] = useState({
    labourCost: 0,
    partCost: 0,
    totalCost: 0,
  });
  const [chartDialog,setChartDialog]=useState({
    MTBF:false,
    MTTR:false,
    PMCompl:false,
    failure:false,
    problem:false,
    causes:false,
    remedy:false,
    openWolist:false,
    overDueWoList:false,
    costWoList:false,
    serWoList:false,
  })
  const [apiData, setStatusOpenOverData] = useState([]);
  const [costData, setCostWoData] = useState([]);
  const [complainWOData, setComplaianceWOListData] = useState([]);
  const [serWodata, setServiceRequestWOlist] = useState([]);
  const [mtbfwoList, setMTBFWOList] = useState([]);
  const [mttrwoList, setgetmttrwoListData] = useState([]);
  const [failurewoList, setfailureWOListData] = useState([]);
  const [problemWolist, setProblemWOListData] = useState([]);
  const [causeWOList, sefaliureCauseWOListData] = useState([]);
  const [remidyWOList, setfailureRemidyWOListData] = useState([]);



  useEffect(() => {
    getData();
  }, []);

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const getData = async() => {
    setIsLoading(true);
    const dateFromandTo = {
      createdDateFrom: getDateFrom,
      createdDateTo: getDateTo,
    };

    const filterData = {
      ...dateFromandTo,
      assetCategoryId: selectCatValue.id,
      assetTypeId: selectTypeValue.id,
      locationId: location.id,
    };

    console.log(filterData);
    const graphData = masterAPI["KPIReport"].getmtbfreport;
    const mttGraphData = masterAPI["KPIReport"].getmttrreport;
    const complaiance = masterAPI["KPIReport"].getcomplaiancechart;
    const woBacklogandOpen = masterAPI["KPIReport"].woBacklogAndOpenStatus;
    const srStatus = masterAPI["KPIReport"].serviceReqStatus;
    const woCostandLabour = masterAPI["KPIReport"].woCostandLabour;
    const failureClassReport = masterAPI["KPIReport"].getFailureClass
    const problemReport = masterAPI["KPIReport"].getProblem
    const causesReport = masterAPI["KPIReport"].getCauses
    const remedyReport = masterAPI["KPIReport"].getRemedies
    const getStatusWoList = masterAPI["KPIReport"].getcompliancereportbystatuswolist 
    const getCostWoList = masterAPI["KPIReport"].getcostreportforwolist
    const getComplaianceWOList = masterAPI["KPIReport"].getKPIReportComplaianceWOList
    const getservicerequestWOlist = masterAPI["KPIReport"].getservicerequestreportlist
    const getmtbfwoList = masterAPI["KPIReport"].getmtbfreportwoList
    const getmttrwoList = masterAPI["KPIReport"].getmttrreportwoList
    const failurefailuresWOList = masterAPI["KPIReport"].failurereportforfailuresWOList
    const failureproblemWOList = masterAPI["KPIReport"].failurereportforproblemWOList
    const faliureCauseWOList = masterAPI["KPIReport"].failurereportforcausesWOList
    const failureRemidyWOList = masterAPI["KPIReport"].failurereportforremedyWOList

    await axiosPrivate
    .post(`${getStatusWoList}`, filterData)
    .then((res)=>{
      console.log("wolist",res.data.result);
      setStatusOpenOverData(res.data.result); 
      setIsLoading(false);
    })
    .catch((e)=>{
      console.log(e);
    });

    await axiosPrivate
    .post(`${failureproblemWOList}`, filterData)
    .then((res)=>{
      console.log("problemWOList",res.data.result);
      setProblemWOListData(res.data.result);
      setIsLoading(false);
    })
    .catch((e)=>{
      console.log(e);
    })

    await axiosPrivate
    .post(`${faliureCauseWOList}`, filterData)
    .then((res)=>{
      console.log("faliureCauseWOList",res.data.result);
      sefaliureCauseWOListData(res.data.result);
      setIsLoading(false);
    })
    .catch((e)=>{
      console.log(e);
    })


    await axiosPrivate
    .post(`${failureRemidyWOList}`, filterData)
    .then((res)=>{
      console.log("failureRemidyWOList",res.data.result);
      setfailureRemidyWOListData(res.data.result);
      setIsLoading(false);
    })
    .catch((e)=>{
      console.log(e);
    })


    await axiosPrivate
    .post(`${failurefailuresWOList}`, filterData)
    .then((res)=>{
      console.log("failureWOList",res.data.result);
      setfailureWOListData(res.data.result); 
      setIsLoading(false);
    })
    .catch((e)=>{
      console.log(e);
    });

    await axiosPrivate
    .post(`${getmttrwoList}`, filterData)
    .then((res)=>{
      console.log("getmttrwoList",res.data.result);
      setgetmttrwoListData(res.data.result); 
      setIsLoading(false);
    })
    .catch((e)=>{
      console.log(e);
    });
     
    await axiosPrivate
    .post(`${getmtbfwoList}`, filterData)
    .then((res)=>{
      console.log("mtbfwolist",res.data.result);
      setMTBFWOList(res.data.result);
      setIsLoading(false);
    })
    .catch((e)=>{
      console.log(e);
    });

    await axiosPrivate
    .post(`${getservicerequestWOlist}`, filterData)
    .then((res)=>{
      console.log("serWOLIST",res.data.result);
      setServiceRequestWOlist(res.data.result); 
      setIsLoading(false);
    })
    .catch((e)=>{
      console.log(e);
    });

    await axiosPrivate
    .post(`${getComplaianceWOList}`, filterData)
    .then((res)=>{
      console.log("Complaiancwolist",res.data.result);
      setComplaianceWOListData(res.data.result); 
      setIsLoading(false);
    })
    .catch((e)=>{
      console.log(e);
    });

    await axiosPrivate
    .post(`${getCostWoList}`, dateFromandTo)
    .then((res)=>{
      console.log("wolistcost",res.data.result);
      setCostWoData(res.data.result); 
      setIsLoading(false);
    })
    .catch((e)=>{
      console.log(e);
    });


    await axiosPrivate
      .post(`${graphData}`, filterData)
      .then((res) => {
        console.log(res.data.result.data);
        const resData = res.data.result.data;
        setDashBoardDataXaxis(resData.xAxis);
        setDashBoardDataYaxis(resData.yAxis);

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setDashBoardDataXaxis([]);
        setDashBoardDataYaxis([]);
        setIsLoading(false);
      });
      await axiosPrivate
      .post(`${mttGraphData}`, filterData)
      .then((res) => {
        console.log(res.data.result.data);
        const resData = res.data.result.data;
        setDashBoardDatamttXaxis(resData.xAxis);
        setDashBoardDatamttYaxis(resData.yAxis);

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setDashBoardDatamttXaxis([]);
        setDashBoardDatamttYaxis([]);
      });

      await axiosPrivate
      .post(`${complaiance}`, filterData)
      .then((res) => {
        console.log(res.data.result.data);
        const resData = res.data.result.data;
        setDashBoardDataComplaianceXaxis(resData.xAxis);
        setDashBoardDataComplaianceYaxis(resData.yAxis);

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setDashBoardDataComplaianceXaxis([]);
        setDashBoardDataComplaianceYaxis([]);
      });

      await axiosPrivate
      .post(`${woBacklogandOpen}`, dateFromandTo)
      .then((res) => {
        console.log(res);
        const overDueFilt = res.data.result.filter(
          (fil) => fil.status.toUpperCase() === "OVERDUE"
        );
        const openFilt = res.data.result.filter(
          (fil) => fil.status.toUpperCase() === "OPEN"
        );
        console.log([overDueFilt, openFilt]);
        setWOCountData({
          backLog: overDueFilt[0].noOfRecords,
          woOpen: openFilt[0].noOfRecords,
        });
      })
      .catch((e) => {
        console.log(e);
        setWOCountData({
          woOpen: 0,
          backLog: 0,
        });
      });

      await axiosPrivate
      .post(`${srStatus}`, dateFromandTo)
      .then((res) => {
        console.log(res);
        const srNewFilt = res.data.result.filter(
          (fil) => fil.status.toUpperCase() === "NEW"
        );
        if (srNewFilt.length !== 0) {
          setSRCountData({
            srNew: srNewFilt[0].noOfRecords,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setSRCountData({
          srNew: 0,
        });
      });

      await axiosPrivate
      .post(`${woCostandLabour}`, dateFromandTo)
      .then((res) => {
        console.log(res);
        let labCost = 0;
        let partCost = 0;
        for (let i = 0; res.data.result.length > i; i++) {
          labCost += res.data.result[i].labourCost;
          partCost += res.data.result[i].partCost;
        }
        setCostAndLab({
          labourCost: labCost,
          partCost: partCost,
          totalCost: labCost + partCost,
        });
      })
      .catch((e) => {
        console.log(e);
        setCostAndLab({
          labourCost: 0,
          partCost: 0,
          totalCost: 0,
        });
      });

      await axiosPrivate.post(`${failureClassReport}`,dateFromandTo).then(res=>{
       
        setFailureData({
              xAxis:res.data.result.failure,
              yAxis:res.data.result.noOfRecords
            })
           
      }).catch(e=>{
        console.log(e)
        setFailureData({
          xAxis:[],
          yAxis:[]
        })
      })

      await axiosPrivate.post(`${problemReport}`,dateFromandTo).then(res=>{
        console.log(res,"problem")
        setProblemData({
          xAxis:res.data.result.problem,
          yAxis:res.data.result.noOfRecords
        })
      }).catch(e=>{
        setProblemData({
          xAxis:[],
          yAxis:[]
        })
      })

      await axiosPrivate.post(`${causesReport}`,dateFromandTo).then(res=>{
        console.log(res,"causes")
        setCausesData({
          xAxis:res.data.result.cause,
          yAxis:res.data.result.noOfRecords
        })
      }).catch(e=>{
        console.log(e)
        setCausesData({
          xAxis:[],
          yAxis:[]
        })
      })

      await axiosPrivate.post(`${remedyReport}`,dateFromandTo).then(res=>{
        console.log(res,"remedy")
        setRemedyData({
          xAxis:res.data.result.remedy,
          yAxis:res.data.result.noOfRecords
        })
      }).catch(e=>{
        console.log(e)
        setRemedyData({
          xAxis:[],
          yAxis:[]
        })
      })

      





      //////////////////////// filter ////////////////////////////////////////////
        const assetCat = masterAPI["assetCategory"].link;
        await axiosPrivate
      .get(`${assetCat}`)
      .then((res) => {
        setAssetCat(res.data.result);
      })
      .catch((e) => {
        console.log(e, "error");
        setAssetCat([]);
      });
    const assetType =  masterAPI["assetType"].link;
    await axiosPrivate
      .get(`${assetType}`)
      .then((res) => {
        setAssetType(res.data.result);
      })
      .catch((e) => {
        console.log(e, "error");
        setAssetType([]);
      });
    const locationLink = masterAPI["location"].link;
    await axiosPrivate
      .get(`${locationLink}`)
      .then((res) => {
        setLocationData(res.data.result);
      })
      .catch((e) => {
        console.log(e, "error");
        setLocationData([]);
      });
  };

  const handleAssetCat = (e) => {
    setSelectCatValue(e.target.value);
  };

  const handleSearch = () => {
    getData();
  };

  const handleChartDialog=(chart)=>{
   setChartDialog({
    ...chartDialog,
    [chart]:true
   })
  }

  return (
    <Grid container spacing={"1rem"} padding={"1rem"}>
      <Grid item xs={12}>
        <Paper sx={{ padding: "1rem" }}>
          <Grid container alignItems={"center"}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#2362B4",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  {open ? (
                    <KeyboardArrowUpIcon
                      sx={{ fontSize: "1.7rem", color: "#2362B4" }}
                    />
                  ) : (
                    <KeyboardArrowDown
                      sx={{ fontSize: "1.7rem", color: "#2362B4" }}
                    />
                  )}{" "}
                </IconButton>
                Filter
              </Typography>
            </Grid>
            <Grid container item xs={6} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#2362B4", color: "white" }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Collapse in={open} timeout={"auto"} unmountOnExit>
            <Grid container spacing={"1.5rem"}>
              <Grid
                container
                item
                xs={12}
                md={3}
                spacing={"1rem"}
                alignItems={"center"}
                // justifyContent={"center"}
              >
                <Grid item xs={12} justifyContent={"flex-end"}>
                  <Typography
                    sx={{
                      fontFamily: "Nunito Sans, sans-serif",
                      fontWeight: 600,
                      color: "#656565",
                    }}
                  >
                    Date Range
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DesktopDatePicker
                        label="Date From"
                        format="DD/MM/YYYY"
                        value={dayjs(getDateFrom)}
                        slotProps={{
                          textField: { size: "small", fullWidth: true },
                        }}
                        onChange={(newValue) => {
                          setGetDateFrom(newValue);
                          console.log(newValue);
                          if (newValue !== null) {
                            if (`${newValue.$d}` !== "Invalid Date") {
                              const dateFrom = moment(newValue.$d).format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setGetDateFrom(dateFrom);
                              // getData(dateFrom, getDateTo);
                            }
                          }
                        }}
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DesktopDatePicker
                        label="Date To"
                        format="DD/MM/YYYY"
                        value={dayjs(getDateTo)}
                        slotProps={{
                          textField: { size: "small", fullWidth: true },
                        }}
                        onChange={(newValue) => {
                          setGetDateTo(newValue);
                          console.log(newValue);
                          if (newValue !== null) {
                            if (`${newValue.$d}` !== "Invalid Date") {
                              const dateTo = moment(newValue.$d).format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setGetDateTo(dateTo);
                              // getData(getDateFrom, dateTo);
                            }
                          }
                        }}
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography
                  sx={{
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: 600,
                    color: "#656565",
                    mb: "1rem",
                  }}
                >
                  Asset Category
                </Typography>
                <Autocomplete
                  name="assetCategoryId"
                  //  value={autoValue}
                  // value={values.assetName.code+"_"+values.assetName.assetName}
                  inputValue={selectCatValue.text}
                  filterOptions={filterOptions}
                  loading={assetCat.length === 0}
                  options={assetCat}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>
                    option.code + " - " + option.assetCategoryName || option
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);
                      setSelectCatValue({ ...selectCatValue, id: value.id });
                    } else {
                      setSelectCatValue({ text: "", id: null });
                      // setFieldValue("failureClassId",null)
                    }
                  }}
                  onInputChange={(e, v) => {
                    setSelectCatValue({ ...selectCatValue, text: v });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder="Search Asset Type"
                      // onChange={handleChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {assetCat.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                </Autocomplete>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  sx={{
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: 600,
                    color: "#656565",
                    mb: "1rem",
                  }}
                >
                  Asset Type
                </Typography>
                <Autocomplete
                  name="assetTypeId"
                  //  value={autoValue}
                  // value={values.assetName.code+"_"+values.assetName.assetName}
                  inputValue={selectTypeValue.text}
                  filterOptions={filterOptions}
                  loading={assetType.length === 0}
                  options={assetType}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>
                    option.code + " - " + option.assetTypeName || option
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);
                      setSelectTypeValue({ ...selectTypeValue, id: value.id });
                    } else {
                      setSelectTypeValue({ text: "", id: null });
                      // setFieldValue("failureClassId",null)
                    }
                  }}
                  onInputChange={(e, v) => {
                    setSelectTypeValue({ ...selectTypeValue, text: v });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder="Search Asset Type"
                      // onChange={handleChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {assetType.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                </Autocomplete>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  sx={{
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: 600,
                    color: "#656565",
                    mb: "1rem",
                  }}
                >
                  Location
                </Typography>
                <Autocomplete
                  name="locationId"
                  //  value={autoValue}
                  // value={values.assetName.code+"_"+values.assetName.assetName}
                  inputValue={location.text}
                  filterOptions={filterOptions}
                  loading={locationData.length === 0}
                  options={locationData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) =>
                    option.code + " - " + option.locationName || option
                  }
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      console.log(value);
                      setLocation({ ...location, id: value.id });
                    } else {
                      setLocation({ text: "", id: null });
                      // setFieldValue("failureClassId",null)
                    }
                  }}
                  onInputChange={(e, v) => {
                    setLocation({ ...location, text: v });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder="Search Asset Type"
                      // onChange={handleChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                            {locationData.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                >
                  {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                </Autocomplete>
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </Grid>

      {isLoading ? (
        <Loading />
      ) : (
        <Grid container item spacing={"1rem"}>
          <Grid item xs={6} md={3}  onClick={()=>{handleChartDialog("openWolist")}} sx={{ cursor: 'pointer' }}>
            <Paper
              sx={{
                padding: "1rem",
                width: "100%",
                height:"100%",
                textAlign: "center",
                background: "#ffbe0b",
              }}
            >
              <Typography sx={{ color: "white", fontWeight: 900 }}>
                Open WO
              </Typography>
              <Typography sx={{ fontSize: "1.5rem", mt: 1, color: "white" }}>
                {wocountData.woOpen}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6} md={3} onClick={()=>{handleChartDialog("overDueWoList")}} sx={{ cursor: 'pointer' }}>
            <Paper
              sx={{
                padding: "1rem",
                width: "100%",
                height:"100%",
                textAlign: "center",
                background: "#ff5c75",
              }}
            >
              <Typography sx={{ color: "white", fontWeight: 900 }}>
                Overdue WO
              </Typography>
              <Typography sx={{ fontSize: "1.5rem", mt: 1, color: "white" }}>
                {wocountData.backLog}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6} md={3} onClick={()=>{handleChartDialog("serWoList")}} sx={{ cursor: 'pointer' }}>
            <Paper
              sx={{
                padding: "1rem",
                width: "100%",
                textAlign: "center",
                height:"100%",
                background: "#25c2e3",
              }}
            >
              <Typography sx={{ color: "white", fontWeight: 900 }}>
                New Service Request
              </Typography>
              <Typography sx={{ fontSize: "1.5rem", mt: 1, color: "white" }}>
                {srCountData.srNew}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6} md={3} onClick={()=>{handleChartDialog("costWoList")}} sx={{ cursor: 'pointer' }}>
            <Paper
              sx={{
                padding: "1rem",
                width: "100%",
                height:"100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#6f42c1",
              }}
            >
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <Typography sx={{ color: "white", fontWeight: 900 }}>
                  Labour Cost :
                </Typography>
                <Typography sx={{ color: "white" }}>
                  {costandLab.labourCost}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <Typography sx={{ color: "white", fontWeight: 900 }}>
                  Part Cost :
                </Typography>
                <Typography sx={{ color: "white" }}>
                  {costandLab.partCost}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <Typography sx={{ color: "white", fontWeight: 900 }}>
                  Total Cost :
                </Typography>
                <Typography sx={{ color: "white" }}>
                  {costandLab.totalCost}
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Paper sx={{ padding: "1rem", overflowX: "scroll", width: "100%",height:"100%",cursor:"pointer" }}
             onClick={()=>{handleChartDialog("MTBF")}}
            >
              <MTBFChart
              dashBoardDataXaxis={dashBoardDataXaxis.slice(0,5)}
              dashBoardDataYaxis={dashBoardDataYaxis.slice(0,5)}
              type={false}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Paper sx={{ padding: "1rem", overflowX: "scroll", width: "100%",height:"100%",cursor:"pointer" }}
            onClick={()=>{handleChartDialog("MTTR")}}
            >
             <MTTRChart 
             dashBoardDatamttXaxis={dashBoardDatamttXaxis.slice(0,5)}
             dashBoardDatamttYaxis={dashBoardDatamttYaxis.slice(0,5)}
             type={false}
             />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Paper sx={{ padding: "1rem", overflowX: "scroll", width: "100%",height:"100%",cursor:"pointer" }}
            onClick={()=>{handleChartDialog("PMCompl")}}
            >
             <PMComplChart 
             type={false}
             dashBoardDataComplaianceXaxis={dashBoardDataComplaianceXaxis.slice(0,5)}
             dashBoardDataComplaianceYaxis={dashBoardDataComplaianceYaxis.slice(0,5)}
             />
             
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Paper sx={{ padding: "1rem", overflowX: "scroll", width: "100%",height:"100%",cursor:"pointer" }}
            onClick={()=>{handleChartDialog("failure")}}
            >
             <FailureClassChart type={false} failureClassXaxis={failureData.xAxis.slice(0,5)} failureClassYaxis={failureData.yAxis.slice(0,5)}/>
             
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Paper sx={{ padding: "1rem", overflowX: "scroll", width: "100%",height:"100%",cursor:"pointer" }}
            onClick={()=>{handleChartDialog("problem")}}
            >
             <ProblemChart type={false} xAxis={problemData.xAxis.slice(0,5)} yAxis={problemData.yAxis.slice(0,5)}/>
             
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Paper sx={{ padding: "1rem", overflowX: "scroll", width: "100%",height:"100%",cursor:"pointer" }}
            onClick={()=>{handleChartDialog("causes")}}
            >
             <CausesChart type={false} xAxis={causesData.xAxis.slice(0,5)} yAxis={causesData.yAxis.slice(0,5)}/>
             
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Paper sx={{ padding: "1rem", overflowX: "scroll", width: "100%",height:"100%",cursor:"pointer" }}
            onClick={()=>{handleChartDialog("remedy")}}
            >
             <RemedyChart type={false} xAxis={remedyData.xAxis.slice(0,5)} yAxis={remedyData.yAxis.slice(0,5)}/>
             
            </Paper>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={chartDialog.MTBF}
        onClose={()=>{setChartDialog({...chartDialog,MTBF:false})}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"80vw"},
            width:{xs:"100vw",md:"75vw"}
          },
        }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <MTBFChart mtbfwoList={mtbfwoList} type={true} dashBoardDataXaxis={dashBoardDataXaxis} dashBoardDataYaxis={dashBoardDataYaxis}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,MTBF:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
      />
      <Dialog
        open={chartDialog.MTTR}
        onClose={()=>{setChartDialog({...chartDialog,MTTR:false})}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"80vw"},
            width:{xs:"100vw",md:"75vw"}
          },
        }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <MTTRChart mttrwoList={mttrwoList} type={true} dashBoardDatamttXaxis={dashBoardDatamttXaxis} dashBoardDatamttYaxis={dashBoardDatamttYaxis}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,MTTR:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
      />
      <Dialog
        open={chartDialog.PMCompl}
        onClose={()=>{setChartDialog({...chartDialog,PMCompl:false})}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"80vw"},
            width:{xs:"100vw",md:"75vw"}
          },
        }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <PMComplChart isDialogOpen={chartDialog.PMCompl} complainWOData={complainWOData} type={true} dashBoardDataComplaianceXaxis={dashBoardDataComplaianceXaxis} dashBoardDataComplaianceYaxis={dashBoardDataComplaianceYaxis}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,PMCompl:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
        />
        <Dialog
        open={chartDialog.failure}
        onClose={()=>{setChartDialog({...chartDialog,failure:false})}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"80vw"},
            width:{xs:"100vw",md:"75vw"}
          },
        }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <FailureClassChart isDialogOpen={chartDialog.failure} failurewoList={failurewoList} type={true} failureClassXaxis={failureData.xAxis} failureClassYaxis={failureData.yAxis}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,failure:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
        />
         <Dialog
        open={chartDialog.problem}
        onClose={()=>{setChartDialog({...chartDialog,problem:false})}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"80vw"},
            width:{xs:"100vw",md:"75vw"}
          },
        }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <ProblemChart isDialogOpen={chartDialog.problem} problemWolist={problemWolist} type={true} xAxis={problemData.xAxis} yAxis={problemData.yAxis}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,problem:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
        />
         <Dialog
        open={chartDialog.causes}
        onClose={()=>{setChartDialog({...chartDialog,causes:false})}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"80vw"},
            width:{xs:"100vw",md:"75vw"}
          },
        }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <CausesChart isDialogOpen={chartDialog.causes} causeWOList={causeWOList} type={true} xAxis={causesData.xAxis} yAxis={causesData.yAxis}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,causes:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
        />
         <Dialog
        open={chartDialog.remedy}
        onClose={()=>{setChartDialog({...chartDialog,remedy:false})}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"80vw"},
            width:{xs:"100vw",md:"75vw"}
          },
        }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <RemedyChart isDialogOpen={chartDialog.remedy} remidyWOList={remidyWOList} type={true} xAxis={remedyData.xAxis} yAxis={remedyData.yAxis}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,remedy:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
        />
           <Dialog
        open={chartDialog.openWolist}
        onClose={()=>{setChartDialog({...chartDialog,openWolist:false})}}
        // PaperProps={{
        //   sx: {
        //     maxWidth: {xs:"100vw",md:"80vw"},
        //     width:{xs:"100vw",md:"75vw"}
        //   },
        // }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <OpenWoList type={true} apiData={apiData}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,openWolist:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
        />
            <Dialog
        open={chartDialog.overDueWoList}
        onClose={()=>{setChartDialog({...chartDialog,overDueWoList:false})}}
        // PaperProps={{
        //   sx: {
        //     maxWidth: {xs:"100vw",md:"80vw"},
        //     width:{xs:"100vw",md:"75vw"}
        //   },
        // }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <OverDueWoList type={true} apiData={apiData}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,overDueWoList:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
        />
          <Dialog
        open={chartDialog.costWoList}
        onClose={()=>{setChartDialog({...chartDialog,costWoList:false})}}
        // PaperProps={{
        //   sx: {
        //     maxWidth: {xs:"100vw",md:"80vw"},
        //     width:{xs:"100vw",md:"75vw"}
        //   },
        // }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <CostWOList type={true} costData={costData}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,costWoList:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
        />
         <Dialog
        open={chartDialog.serWoList}
        onClose={()=>{setChartDialog({...chartDialog,serWoList:false})}}
        // PaperProps={{
        //   sx: {
        //     maxWidth: {xs:"100vw",md:"80vw"},
        //     width:{xs:"100vw",md:"75vw"}
        //   },
        // }}
        children={
          <Grid container>
            <Grid item xs={12}>
            <ServiceWOList type={true} serWodata={serWodata}/>
            </Grid>
            <Divider/>
            <Grid container item justifyContent={"flex-end"}>
              <Button onClick={()=>setChartDialog({...chartDialog,serWoList:false})}>
                Close
              </Button>
              </Grid>
          </Grid>
        }
        />
    </Grid>
  );
};

export default Dashboard;
