import { useState } from 'react';
import Chart from "react-apexcharts";
import { Dialog, Button, Typography, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import NormalTable from "../../Component/NormalTable";  
import { color } from "../../StyleData/Config";
const RemedyChart = ({xAxis,yAxis,type,isDialogOpen, remidyWOList}) => {
  console.log('remidyWOList',remidyWOList)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRemedyCode, setSelectedRemedyCode] = useState('');
  const [workOrders, setWorkOrders] = useState([]);
 const handleChartClick = (e, chartContext, config) => {
    const dataPointIndex = config?.dataPointIndex;
    if (dataPointIndex !== undefined && isDialogOpen) {
      const remedy = xAxis[dataPointIndex];
      setSelectedRemedyCode(remedy);
      // Find all work orders with the selected failure code
      const filteredWorkOrders = remidyWOList.filter(item => item.remedy === remedy);
      setWorkOrders(filteredWorkOrders);
      setDialogOpen(true); // Open the dialog
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedRemedyCode('');
    setWorkOrders([]);
  };

  const columns = [
    {
      Header: "WO Code",
      accessor: "workOrderCode",
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/workorderview?id=${cell.row.original.id}`}  
            style={{
              color: "#0d6efd",
              textDecorationLine: "underline",
              fontWeight: 800,
            }}
             target="_blank"
            rel="noopener noreferrer"
          >
            {cell.value} 
          </Link>
        );
      },
    },
    {
      Header: "WO Name",
      accessor: "workOrderName",  
    },
  ];

  
  return (
    <>
    <Chart
    height={type?
     ( yAxis.length
        ? `${32 * yAxis.length + 200}px`
        : "100%"):200
    }
    width={"100%"}
    options={{
      
      responsive: [
        {
          breakpoint: 768, // Change the breakpoint as needed
          options: {
            chart: {
              width: 600,
            },
          },
        },
      ],
      series: [
        {
          data: xAxis,
        },
      ],
      title: {
        text: "Remedy Report",
        style: {
          fontFamily: "Nunito Sans, sans-serif",
          color: "#2362B4",
        },
      },

      chart: {
        type: "bar",
        toolbar: {
          export: {
            csv: {
              filename: "Remedy",
            },
            svg: {
              filename: "Remedy",
            },
            png: {
              filename: "Remedy",
            },
          },
        },
        events: {
          dataPointSelection: handleChartClick, // Handle chart click event
        },
      },
    //   grid:{
    //     padding:{
    //         bottom:-10
    //     }
    //   },

      plotOptions: {
        bar: {

          borderRadius: 4,
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
                fontFamily: "Nunito Sans, sans-serif",
              },
            },
          },
        },
        noData: {
          text: "No Result",
          style: {
            fontSize: "1.5rem",
            color: "grey",
            fontFamily: "Nunito Sans, sans-serif",
          },
        },

        stroke: {
          width: 1,
          colors: ["#fff"],
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        title: {
          text: "No of Records",
          // style:{
          //  padding:10
          // }
        },
        labels:{
            show:type
        }
      },
      xaxis: {
        title: {
          text: "Remedy",
          style: {
            fontSize: '11px' // Set the font size of the x-axis title
          }
        },
        // labels:{
        //     show:false
        // },
        categories: xAxis,

        
      },
    }}
    type="bar"
    series={[{ data: yAxis }]}
  />
  <Dialog open={dialogOpen} onClose={handleDialogClose}>
  <Grid container>
    <Grid item xs={12}>
      <Box>
        {/* <Typography sx={{ color: '#2362B4', fontSize: '16px', fontWeight: 600, mb: 2, px: 2, py: 1 }}>
          Work Orders for Failure Code: <span style={{ color: '#0d6efd' }}>{selectedRemedyCode}</span>
        </Typography> */}
        <Typography sx={{ color: color.masterPageTitleColor, fontSize: color.masterPageTitleFontSize, fontWeight: color.masterPageTitleFontWeight, mb: color.masterPageTitlemd, px: 2, py: 1 }}>
        Work Orders for Remedy
          </Typography>
           <Typography sx={{ color: color.masterPageTitleColor, fontSize: color.masterPageTitleFontSize, fontWeight: color.masterPageTitleFontWeight, mb: color.masterPageTitlemd, px: 2, py: 1 }}>
                        Remedy Code: <span style={{ color: color.masterDialogTitleColor }}>{selectedRemedyCode}</span>
                        </Typography>
        <Box sx={{ px: 2, py: 1 }}>
          {workOrders.length > 0 ? (
            <NormalTable data={workOrders} columns={columns} filt={true} />
          ) : (
            <Typography sx={{ color: 'grey', fontSize: '14px' }}>No work orders found for this failure code.</Typography>
          )}
        </Box>
      </Box>
    </Grid>
    <Grid container item justifyContent={"flex-end"}>
      <Button onClick={handleDialogClose}>Close</Button>
    </Grid>
  </Grid>
</Dialog>
</>
  )
}

export default RemedyChart
