import { Box, Button, Grid, Paper, Switch, Tab, Typography, Dialog, DialogContent, DialogTitle, IconButton  } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { color } from '../../../StyleData/Config';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Loading from '../../../Component/Loading';
import { useSearchParams } from 'react-router-dom';
import { masterAPI } from '../../Masters/dataConfig';
import { axiosPrivate } from '../../../axios/axios';
import CloseIcon from '@mui/icons-material/Close';

const PartView = ({partId, handleClose}) => {
    const [data,setData]=useState({})
    const [loading,setLoading]=useState(false)
    const [value,setValue]=useState("1")
    const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClosedialog = () => {
    setOpen(false);
  };
    useEffect(()=>{
        getData()
    },[])

    const getData=async()=>{
        setLoading(true)
        const id = partId || searchParams.get("id"); 
        const link=masterAPI["parts"].getById
       await axiosPrivate.get(`${link}/${id}`).then(res=>{
          console.log(res)
         setData(res.data.result)
         setLoading(false)
        }).catch(e=>{
            console.log(e)
            setLoading(false)
            setData({})
        })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

  return (
    <Box>
    {/* <Box sx={{width: {xs:direction==="column"?"50%":"100%",lg:direction==="column"?"50%":"100%"}}}> */}
    <Grid container spacing={"1rem"}>
      <Grid
        item
        xs={12}
      >
           <Box
            sx={{
                display: "flex",
                width: "95%",
                justifyContent: "space-between",
                p: 1,
                alignItems: "center",
                position: "relative" // Ensures proper positioning of the close icon
            }}
        >
            <Typography
                sx={{
                    fontSize: color.AssetTitlesize,
                    fontWeight: color.sideFontWeightAsset,
                }}
            >
                Parts: {data.code}: {data.partName}
            </Typography>
            {partId && (
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: -40, // Move the icon button slightly to the right
                        color: color.CancelTextColor,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </Box>
        {loading ? (
          <Loading />
        ) : (
          <TabContext value={value}>
            <Box
              sx={{
                ".css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                  color: color.TabLabelcolor,
                  // p: 0.5,
                },
                display: "flex",
                justifyContent: "space-between",
                width: "95%",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  sx: { backgroundColor: color.TabLabelcolor, height: "3px" },
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="General"
                  value="1"
                  sx={{ textTransform: "none" }}
                />
              
              </TabList>
              </Box>
            <TabPanel value="1" sx={{p:1}}>
              <Box sx={{
                display:"flex",
                width:"100%",
                p:1.5, 
                boxShadow: "-1px 1px 7px 2px rgb(0 0 0 / 32%)",
                backgroundColor: "white",
                
                }}>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Parts Code
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.code}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Part Name
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.partName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Part Description
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.partDescription}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Part Group
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.partGroupName}
                </Typography>
              </Grid>

             

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Purchase UOM
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.purchasedUomName}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Issued UOM
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.issuedUomName}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Criticality
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.criticalityName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Technical Specification
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.techSpecName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Qr Code Data
                </Typography>
                <Typography
                sx={{
                  fontSize: color.TabDatafontsize,
                  mb: color.TabDatamarginbottom,
                }}
              >
                {data.qrCodeData}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Rotaing
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    // flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    In Active
                  </Typography>

                  <Switch
                    checked={data.isRotating}
                    value={data.isRotating}
                    disabled
                    sx={{
                      width: 48,
                      height: 26,
                      padding: 0,

                      "& .MuiSwitch-switchBase": {
                        padding: 0,
                        margin: 0.2,
                        transitionDuration: "300ms",
                        "&.Mui-checked": {
                          transform: "translateX(22px)",
                          color: "#fff",
                          "& + .MuiSwitch-track": {
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#2ECA45"
                                : "#65C466",
                            opacity: 1,
                            border: 0,
                          },
                          "&.Mui-disabled + .MuiSwitch-track": {
                            opacity: 0.5,
                          },
                        },
                        "&.Mui-focusVisible .MuiSwitch-thumb": {
                          color: "#33cf4d",
                          border: "6px solid #fff",
                        },
                        "&.Mui-disabled .MuiSwitch-thumb": {
                          color: (theme) =>
                            theme.palette.mode === "light"
                              ? theme.palette.grey[100]
                              : theme.palette.grey[600],
                        },
                        "&.Mui-disabled + .MuiSwitch-track": {
                          opacity: (theme) =>
                            theme.palette.mode === "light" ? 0.7 : 0.3,
                        },
                      },
                      "& .MuiSwitch-thumb": {
                        boxSizing: "border-box",
                        width: 22,
                        height: 22,
                      },
                      "& .MuiSwitch-track": {
                        borderRadius: 26 / 2,
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#E9E9EA"
                            : "#39393D",
                        opacity: 1,
                        transition: (theme) =>
                          theme.transitions.create(["background-color"], {
                            duration: 500,
                          }),
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    Active
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  QC Passed
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    // flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    In Active
                  </Typography>
                  <Switch
                    checked={data.isQCPassed}
                    value={data.isQCPassed}
                    disabled
                    sx={{
                      width: 48,
                      height: 26,
                      padding: 0,

                      "& .MuiSwitch-switchBase": {
                        padding: 0,
                        margin: 0.2,
                        transitionDuration: "300ms",
                        "&.Mui-checked": {
                          transform: "translateX(22px)",
                          color: "#fff",
                          "& + .MuiSwitch-track": {
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#2ECA45"
                                : "#65C466",
                            opacity: 1,
                            border: 0,
                          },
                          "&.Mui-disabled + .MuiSwitch-track": {
                            opacity: 0.5,
                          },
                        },
                        "&.Mui-focusVisible .MuiSwitch-thumb": {
                          color: "#33cf4d",
                          border: "6px solid #fff",
                        },
                        "&.Mui-disabled .MuiSwitch-thumb": {
                          color: (theme) =>
                            theme.palette.mode === "light"
                              ? theme.palette.grey[100]
                              : theme.palette.grey[600],
                        },
                        "&.Mui-disabled + .MuiSwitch-track": {
                          opacity: (theme) =>
                            theme.palette.mode === "light" ? 0.7 : 0.3,
                        },
                      },
                      "& .MuiSwitch-thumb": {
                        boxSizing: "border-box",
                        width: 22,
                        height: 22,
                      },
                      "& .MuiSwitch-track": {
                        borderRadius: 26 / 2,
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#E9E9EA"
                            : "#39393D",
                        opacity: 1,
                        transition: (theme) =>
                          theme.transitions.create(["background-color"], {
                            duration: 500,
                          }),
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: color.TabDatafontsize,
                      mb: color.TabDatamarginbottom,
                    }}
                  >
                    Active
                  </Typography>
                </Box>
              </Grid>
              
            </Grid>
            <Paper elevation={3} sx={{width:"30vw",height:"40vh",p:0.5,display:"flex",alignItems:"center",justifyContent:"center"}}>
             <img src={data.partImage} alt="productImage" style={{maxHeight: "100%",maxWidth:"100%"}} onClick={handleClickOpen}/> 
          </Paper>
          
          </Box>
            </TabPanel>
         
          </TabContext>
        )}
       
      </Grid>

    </Grid>
    <Dialog open={open} onClose={handleClosedialog}>
        <DialogTitle>Image Preview</DialogTitle>
                <IconButton
                    onClick={handleClosedialog}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: color.CancelTextColor,
                    }}
                >
                    <CloseIcon />
                </IconButton>
        <DialogContent>
          <img src={data.partImage} alt="productImage" style={{ width: '100%' }} />
        </DialogContent>
      </Dialog>
  </Box>
  )
}

export default PartView