import React, { useEffect, useState } from "react";
import TableContainer from "../../../Component/TableContainer";
import { Box, Checkbox, Dialog, IconButton, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { masterAPI } from "../dataConfig";
import { axiosPrivate } from "../../../axios/axios";
import {useSelector} from "react-redux"
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import TechnicalSpecAttrModal from "./TechSpecAttrModal";
import CloneTechSpec from "./CloneTechSpec";


const TechnicalSpecAttr = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const actionsAllow=useSelector(state=>state.dataRed.actionsAllow)

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [cloneOption,setCloneOption]=useState(false)

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    setIsLoading(true);
    const id=searchParams.get("id")
    const link = masterAPI["techSpecAttr"].getByTechSpecId;
   await axiosPrivate
      .get(`${link}/${id}`)
      .then((res) => {
        console.log(res.data, "technicalSpecAttributes result");
        setData(res.data.result);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e, "technicalSpecAttributes");
        setIsLoading(false);
        setData([])
      });
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  const handleDelete = async(id) => {
    const deleteLink = masterAPI["techSpecAttr"].delete;
   await axiosPrivate
      .delete(`${deleteLink}/${id}`)
      .then((res) => {
        console.log(res.data);
        getData();
      })
      .catch((e) => {
        console.log(e, "delete technicalSpecAttributes");
      });
  };

  const columns = [
    {
      accessor: "id",
      disableFilters: true,
      Cell: () => {
        return (
          <Box sx={{width:color.checkBoxWidth }}>
          <Checkbox
            // icon={<RadioButtonUncheckedOutlined />}
            // checkedIcon={<CheckCircle />}
          />
          </Box>
        );
      },
    },
 
    {
      Header: "Attribute Name",
      accessor: "technicalAttributes.attributeName",
      // disableFilters: true,
    },
    {
      Header: "Technical Specification Name",
      accessor: "technicalSpecification.techSpecName",
    },
   
    {
      Header: "Attribute Value",
      accessor: "attributeNumericValue",
      Cell: ({ value }) => (value === 0 ? null : value),
    },
    {
      Header: "Attribute Text",
      accessor: "attributeTextValue",
    },
    {
      Header: "Attribute Date",
      accessor: "attributeDateValue",
    },
   

    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton disabled={!actionsAllow.edit}>
              <EditOutlined
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: actionsAllow.edit? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton>
            <IconButton disabled={!actionsAllow.delete}>
              <DeleteOutline
                onClick={() => {
                  handleDelete(cell.row.original.id);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: actionsAllow.delete? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleCloneButton=()=>{
   setCloneOption(true)
  }

  const handleCloseClone=()=>{
    setCloneOption(false)
  }

  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI['techSpecAttr'].title}
        </Typography>
      </Box>
     
        <TableContainer
          columns={columns}
          data={data}
          buttonNew={handleButtonNew}
          loading={isLoading}
          buttonDisable={!actionsAllow.create}
          clone={true}
          handleCloneButton={handleCloneButton}
        />
   

      <Dialog
        open={create}
        onClose={handleCreateClose}
        PaperProps={{
          sx: {
            maxWidth: '70vw',
            width:"65vw"
          },
        }}
        children={
          <TechnicalSpecAttrModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
      <Dialog
        open={cloneOption}
        onClose={handleCloseClone}
        PaperProps={{
          sx: {
            maxWidth: '70vw',
            width:"65vw"
          },
        }}
        children={
          <CloneTechSpec
            handleCloseClick={handleCloseClone}
            
          />
        }
      />
    </>
  );
};

export default TechnicalSpecAttr;
