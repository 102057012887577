


import React, { useEffect, useState } from "react";
import TableContainer from "../../Component/TableContainer";
import {
  Box,
  Checkbox,
  Dialog,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { masterAPI } from "../Masters/dataConfig";
import { axiosPrivate } from "../../axios/axios";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  ModeEdit,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { color } from "../../StyleData/Config";
import CreateWO from "../WorkManagement/WorkOrder/WO";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import NormalTable from "../../Component/NormalTable";
import SRView from "../WorkManagement/ServiceRequest/SRView";
// import AssetModal from "./AssetModal";
// import CreateAssetCategory from "./AssetCategoryModal";

const ServiceWOList = (serWodata) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
    const [SRID,setSRID]=useState("")
    const [view,setView]=useState(false)
  console.log('serWodata:', serWodata); 
  useEffect(() => {
    getData();
  }, []);
console.log('data',data);
  const getData = async() => {
    //const filteredData = costData.costData.filter(item => item.status === 'OPEN');
    setData(serWodata.serWodata);
  };
  
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  
  const handleView=(id)=>{
    setView(true)
    setSRID(id)
  }
  
  const handleCloseView=()=>{
    setView(false)
    setSRID("")
  }
  const columns = [

    {
      Header: "Service Request Code",
      accessor: "code",

      Cell: ({ cell }) => {
        return (
               <Typography
                    sx={{
                      fontSize: "inherit",
                      fontWeight: "inherit",
                      color: "#0d6efd",
                      textDecoration:"underline",
                      cursor:"pointer"
                    }}
                    onClick={()=>handleView(cell.row.original.id)}
                  >
                    {cell.value}
                  </Typography>
        );
      },
    },
    {
      Header: "Service Request Name",
      accessor: "serviceRequestName",
    },

   
  ];



  return (
    <>
      <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
            px: 2, 
            py: 1,
          }}
        >
        New Service Requests
        </Typography>
      </Box>
      <Box
    sx={{
      px: 2, 
      py: 1, 
    }}
  >
    <NormalTable data={data} columns={columns} filt={true} />
  </Box>
      <Dialog
        open={create}
        onClose={handleCreateClose}
        maxWidth={"md"}
        children={
          <CreateWO
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        }
      />
        <Dialog
        open={view}
        onClose={handleCloseView}
        PaperProps={{
          sx: {
            maxWidth: '70vw',
            width:"65vw"
          },
        }}
        children={
          <SRView
            handleCloseClick={handleCloseView}
            viewId={SRID}
            isDialogOpen={view}
          />
        }
      />

    </>
  );
};

export default ServiceWOList;
