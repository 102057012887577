import React from 'react';

function DateFormatter({ dateString, formatOptions }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', formatOptions || {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  return <span>{formatDate(dateString)}</span>;
}

export default DateFormatter;
