import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import { axiosPrivate } from "../../../axios/axios";
import { masterAPI } from "../../Masters/dataConfig";
import moment from "moment";
import { DatePicker, DateTimePicker, LocalizationProvider,renderTimeViewClock } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

const SRTab = ({ mode, handleCloseClick, editData }) => {
  const [problemCode, setProblemCode] = useState([]);
  const [failureClass, setFailureClass] = useState([]);
  const [asset, setAsset] = useState([]);
  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [workType, setWorkType] = useState([]);
  const [auto,setAuto]=useState({
    asset:mode === "Edit"?editData.assetCode+" - "+editData.assetName:"",
    location:mode === "Edit"?editData.locationCode+" - "+editData.locationName:""
  })
  const [assetInfo,setAssetInfo]=useState({})

  useEffect(() => {
    getDataList()
  }, []);

  const getDataList=async()=>{
    setLoading(true);
    getData()
    if (mode === "Edit") {
      const problemLink = masterAPI["problems"].link;
     await axiosPrivate
        .get(`${problemLink}/${editData.failureClassId}`)
        .then((res) => {
          console.log(res, "problemcode");
          setProblemCode(res.data.result);
        })
        .catch((e) => {
          setProblemCode([])
          console.log(e, "error problem sr");
        });
    }
    const link = masterAPI["failureClass"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false);
        setFailureClass(res.data.result);
      })
      .catch((e) => {
        setFailureClass([])
        console.log(e, "createfailureCodegetAll");
      });

   
    const locLink = masterAPI["location"].link;
   await axiosPrivate
      .get(`${locLink}`)
      .then((res) => {
        setLoading(false);
        setLocation(res.data.result);
      })
      .catch((e) => {
        setLocation([])
        console.log(e, "create location getAll");
      });

    const workTypeLink = masterAPI["selectList"].workordertype;
   await axiosPrivate
      .get(`${workTypeLink}`)
      .then((res) => {
        setLoading(false);
        setWorkType(res.data);
      })
      .catch((e) => {
        setWorkType([])
        console.log(e, "create location getAll");
      });
  }

  const getData=async()=>{
    const assetLink = masterAPI["assets"].link;
   await axiosPrivate
      .get(`${assetLink}`)
      .then((res) => {
        setLoading(false);
        setAsset(res.data.result);
      })
      .catch((e) => {
        setLoading(false)
        setAsset([])
        console.log(e, "create asset getAll");
      });
  }

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const initialValue = {
    problemId: mode === "Edit" ? editData.problemId : "",
    failureClassId: mode === "Edit" ? editData.failureClassId : "",
    locationId: mode === "Edit" ? editData.locationId : "",
    assetId: mode === "Edit" ? editData.assetId : "",
    assetName: mode ==="Edit"?editData.assetName:"",
    serviceRequestName: mode === "Edit" ? editData.serviceRequestName : "",
    description: mode === "Edit" ? editData.description : "",
    reasontoCancel: mode === "Edit" ? editData.reasontoCancel : "",
    workTypeId: mode === "Edit" ? editData.workTypeId : "d707dbff-d08f-42a8-afa1-12b2e48c266c",
    requestedDate:
      mode === "Edit"
        ? editData.requestedDate
        : moment().format("YYYY-MM-DDTHH:mm:ss"),
    stopBeginDate:
      mode === "Edit"
        ? editData.stopBeginDate
        : moment().format("YYYY-MM-DDTHH:mm:ss"),
    closingDate:
      mode === "Edit"
        ? editData.closingDate
        : moment().format("YYYY-MM-DDTHH:mm:ss"),
    approvalDate:
      mode === "Edit"
        ? editData.approvalDate
        : null,
  };

  const onSubmit = (value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["serviceRequest"].create;
    const updateLink = masterAPI["serviceRequest"].update;
    // if (mode === "Create") {
    //   axiosPrivate
    //     .post(`${createLink}`, value)
    //     .then((res) => {
    //       console.log(res.data);
    //       setLoading(false);
    //       handleCloseClick();
    //     })
    //     .catch((e) => {
    //       setLoading(false);
    //       console.log(e, "create serviceRequest");
    //     });
    // } else {
    //   const updateAC = {
    //     id: editData.id,
    //     ...value,
    //   };
    //   axiosPrivate
    //     .put(`${updateLink}`, updateAC)
    //     .then((res) => {
    //       console.log(res.data);
    //       setLoading(false);
    //       handleCloseClick();
    //     })
    //     .catch((e) => {
    //       setLoading(false);
    //       console.log(e, "update serviceRequest");
    //     });
    // }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  const handleFailureProblem=async(fcid)=>{
    const pcLink=masterAPI["problems"].problembyFailureClass
   await axiosPrivate.get(`${pcLink}/${fcid}`).then(res=>{  
    console.log(res.data.result)
      setProblemCode(res.data.result)
    }).catch(e=>{
      setProblemCode([])
      console.log(e,"Problem Create Wo")
    })
   }

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode==="Edit"?editData.code+"-"+editData.serviceRequestName:"New Service Request"}
       
        </Typography>
     
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Service Request Name
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                
                  name="serviceRequestName"
                  placeholder="Enter Service Request Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Description
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="description"
                  placeholder="Enter Description"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset
                </Typography>
                {/* <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="assetId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.assetId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {asset.map((id, i) => (
                    <MenuItem value={id.id}>{id.assetName}</MenuItem>
                  ))}
                </Field> */}
                
                <Field
                       name="assetId"
                      //  value={autoValue}
                      // value={values.assetName.code+"_"+values.assetName.assetName}
                           inputValue={auto.asset}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={asset.length === 0}
                           options={asset} 
                           PaperComponent={CustomPaper}
                           getOptionLabel={(option) =>option.code+" - "+ option.assetName || option}
                           freeSolo
                         onChange={(event,value) => {
                          
                       if(value){
                        console.log(value)
                        setFieldValue("assetId",value.id)
                        setFieldValue("locationId",value.locationId)
                        setFieldValue("failureClassId",value.failureClassesId)
                        setAuto({...auto,asset:value.code+" - "+value.assetName,location:value.locationCode+" - "+value.locationName})
                        setAssetInfo(value)
                        handleFailureProblem(value.failureClassesId)
                       }else{
                        setFieldValue("assetId",null)
                        setAuto({...auto,asset:""})
                        setAssetInfo({})
                       }
                        
                        }}
                        onInputChange={(e,v)=>{
                              setAuto({...auto,asset:v})
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="assetId"
                           placeholder="Search Asset"
                            onChange={handleChange}
                            // variant="standard"
                            size="small"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {asset.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Location
                </Typography>
                {/* <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="locationId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.locationId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {location.map((id, i) => (
                    <MenuItem value={id.id}>{id.locationCode+" - "+id.locationName}</MenuItem>
                  ))}
                </Field> */}
                 <Field
                       name="locationId"
                      //  value={autoValue}
                      // value={values.assetName.code+"_"+values.assetName.assetName}
                     inputValue={auto.location}
                     readOnly={assetInfo.locationId!==undefined&&assetInfo!==null}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={location.length === 0}
                           options={location} 
                           PaperComponent={CustomPaper}
                           getOptionLabel={(option) => option.code+" - "+option.locationName || option}
                           freeSolo
                         onChange={async(event,value) => {
                          
                       if(value){
                        console.log(value)
                        const assetByLocation=masterAPI["assets"].assetByLocation
                       await axiosPrivate.get(`${assetByLocation}/${value.id}`).then(res=>{
                          setAsset(res.data.result)
                        }).catch(e=>{
                          setAsset([])
                          console.log(e,"error")
                        })
                        setFieldValue("locationId",value.id)
                        setAuto({...auto,asset:"",location:value.code+ " - "+value.locationName})
                        setAssetInfo({})
                    
                       }else{
                        setFieldValue("locationId",null)
                        setAuto({...auto,location:"",asset:""})
                        getData()
                        setAssetInfo({})
                        // setFieldValue("failureClassId",null)
                       }
                        
                        }}
                        onInputChange={(e,v)=>{
                          setAuto({...auto,location:v})
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="locationId"
                           placeholder="Search location"
                            onChange={handleChange}
                            size="small"
                            // variant="standard"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {location.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Failure Class
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="failureClassId"
                  readOnly={assetInfo.failureClassesId!==undefined&&assetInfo.failureClassesId!==null}
                  fullWidth
                  onChange={async(e) => {
                    console.log(e.target.value);
                    const codeLink = masterAPI["problems"].link;
                   await axiosPrivate
                      .get(`${codeLink}/${e.target.value}`)
                      .then((res) => {
                        setLoading(false);
                        setProblemCode(res.data.result);
                      })
                      .catch((e) => {
                        console.log(e, " problem service req");
                      });
                    setFieldValue("failureClassId", e.target.value);
                  }}
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.failureClassId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {failureClass.map((id, i) => (
                    <MenuItem value={id.id}>{id.code+" - "+id.failureClassName}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Problem Code
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="problemId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.problemId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {problemCode.map((id, i) => (
                    <MenuItem value={id.id}>
                      {id.code + " - " + id.problemName}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Work Type
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="workTypeId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.workTypeId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {workType.map((type, i) => (
                    <MenuItem key={i} value={type.value} sx={{display:type.text.toUpperCase()==="PM"?"none":"default"}}>
                      {type.text}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Requested Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY HH:mm"
                      value={dayjs(values.requestedDate)}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      readOnly
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0",fullWidth:true },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("requestedDate", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Stop Begin Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                    <DateTimePicker
                      format="DD/MM/YYYY HH:mm"
                      value={dayjs(values.stopBeginDate)}
                      ampm={false}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0",fullWidth:true },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("stopBeginDate", dateFrom);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              {/* <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Closing Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={dayjs(values.closingDate)}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0",fullWidth:true },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("closingDate", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Approval Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={values.approvalDate===null?values.approvalDate:dayjs(values.approvalDate)}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0",fullWidth:true },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("approvalDate", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid> */}
              <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SRTab;
