import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    createFilterOptions,
    Tab,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { TabContext, TabList, TabPanel } from "@mui/lab";
  
  import { Add, Clear, DeleteOutline } from "@mui/icons-material";
  import { color } from "../../../StyleData/Config";
  import { masterAPI } from "../../Masters/dataConfig";
  import { axiosPrivate } from "../../../axios/axios";
  import moment from "moment";
  import NormalTable from "../../../Component/NormalTable";
  import { toast } from "react-toastify";
  import Loading from "../../../Component/Loading";
  import DocUpload from "../../../Component/DocUpload";
  
  const LocationView = ({ viewId, handleCloseClick, isDialogOpen }) => {
    console.log('isDialogOpen',isDialogOpen)
    const [value, setValue] = useState("1");
    const [comDetail, setComDetail] = useState({});
    const [docData, setDocData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [add,setAdd]=useState(false)
    const [woData,setWoData]=useState([])
  
    useEffect(() => {
     getData()
    }, []);
  
    const getData=async()=>{
       setLoading(true);
      const srLink = masterAPI["location"].getbyid;
     await axiosPrivate
        .get(`${srLink}/${viewId}`)
        .then((res) => {
          setComDetail(res.data.result);
          console.log(res.data.result)
        })
        .catch((e) => {
          setComDetail([])
          console.log(e);
        });
  
      const docLink = masterAPI["serviceRequest"].getdocbyid;
     await axiosPrivate
        .get(`${docLink}/${viewId}`)
        .then((res) => {
          setDocData(res.data.result);
        })
        .catch((e) => {
          setDocData([])
          console.log(e);
        });
  
        const woDataLink=masterAPI["workOrder"].getByServiceReqId
        await axiosPrivate
        .get(`${woDataLink}/${viewId}`)
        .then((res) => {
          console.log(res)
          setWoData([res.data.result]);
        })
        .catch((e) => {
          setWoData([])
          console.log(e);
        });
        setLoading(false)
  
    }
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleCancel = () => {
      handleCloseClick();
    };
  
    // const handleDelete = (id) => {
    //     const deleteLink = masterAPI["serviceRequest"].deleteDoc;
    //     console.log(`${deleteLink}/${id}`)
    //     axiosPrivate
    //       .delete(`${deleteLink}/${id}`)
    //       .then((res) => {
    //         console.log(res.data);
    //         getData();
    //       })
    //       .catch((e) => {
    //         console.log(e, "delete Doc");
    //       });
    //   };
  
    const handleDownload = async(id) => {
      const downloadLink = masterAPI["serviceRequest"].download;
      await axiosPrivate
        .get(`${downloadLink}/${id}`)
        .then((res) => {
          console.log(res);
          window.open(res.data.result.documentPath);
          //  window.open(`https://demo.orienseam.com/${res.data.result.documentName}}`)
        })
        .catch((e) => {
          console.log(e);
        });
    };
  
    const handleDelete = async(id) => {
      const deleteLink = masterAPI["serviceRequest"].deleteDoc;
      console.log(`${deleteLink}/${id}`)
     await axiosPrivate
        .delete(`${deleteLink}/${id}`)
        .then((res) => {
          console.log(res.data);
          getData();
        })
        .catch((e) => {
          console.log(e, "delete Doc");
        });
    };
  
    const columns = [
      // {
      //   Header: "Doc ID",
      //   accessor: "id",
      //   Cell: ({ cell }) => {
      //     return (
      //       <Typography
      //         sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" }}
      //       >
      //         {cell.value}
      //       </Typography>
      //     );
      //   },
      // },
      {
        Header: "Doc Name",
        accessor: "documentName",
        Cell: ({ cell }) => {
          return (
            <Typography
              sx={{
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "blue",
                cursor: "pointer",
              }}
              onClick={() => {
                handleDownload(cell.row.original.id);
              }}
            >
              {cell.value.split("/")[2].split("_")[1]}
            </Typography>
          );
        },
      },
      {
        Header: "Doc Type",
        accessor: "documenttype",
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => {
          // console.log(cell)
          return (
            <Box sx={{ whiteSpace: "nowrap" }}>
  
              <IconButton disabled={comDetail.status!=="NEW"}  onClick={() => {
                    handleDelete(cell.row.original.id);
                  }}>
                <DeleteOutline
  
                  sx={{
                    fontSize: color.masterEditDelButton,
                    color: color.masterEditDelButtonColor,
                  }}
                />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  
    const columnWO = [
      // {
      //   Header: "Doc ID",
      //   accessor: "id",
      //   Cell: ({ cell }) => {
      //     return (
      //       <Typography
      //         sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" }}
      //       >
      //         {cell.value}
      //       </Typography>
      //     );
      //   },
      // },
      {
        Header: "Workorder Code",
        accessor: "code",
        Cell: ({ cell }) => {
          return (
            <Typography
              sx={{
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "blue",
                cursor: "pointer",
              }}
              // onClick={() => {
              //   handleDownload(cell.row.original.id);
              // }}
            >
              {cell.value}
            </Typography>
          );
        },
      },
      {
        Header: "Workorder Name",
        accessor: "workOrderName",
      },
      {
        Header:"Responsible Person",
        accessor:"responsiblePersonName"
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Planned Start Date",
        accessor: "plannedStartDate",
        Cell: ({ cell }) => {
          return (
            <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
              {cell.value !== null && cell.value !== undefined
                ? moment(cell.value).format("DD/MM/YYYY")
                : "---"}
            </Typography>
          );
        },
      },
      {
        Header: "Planned End Date",
        accessor: "plannedEndDate",
        Cell: ({ cell }) => {
          return (
            <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
              {cell.value !== null && cell.value !== undefined
                ? moment(cell.value).format("DD/MM/YYYY")
                : "---"}
            </Typography>
          );
        },
      },
      // {
      //   Header: "Actions",
      //   Cell: ({ cell }) => {
      //     // console.log(cell)
      //     return (
      //       <Box sx={{ whiteSpace: "nowrap" }}>
  
      //         <IconButton disabled={comDetail.status!=="NEW"}  onClick={() => {
      //               handleDelete(cell.row.original.id);
      //             }}>
      //           <DeleteOutline
  
      //             sx={{
      //               fontSize: color.masterEditDelButton,
      //               color: color.masterEditDelButtonColor,
      //             }}
      //           />
      //         </IconButton>
      //       </Box>
      //     );
      //   },
      // },
    ];
  
    const handleGenerate = async() => {
      const genWo = masterAPI["serviceRequest"].coverttowo;
      
      await axiosPrivate
        .put(`${genWo}/${viewId}`)
        .then((res) => {
          console.log(res, "genWO");
          toast.success(res.data.message);
             getData()
          //    setPmGen([])
        })
        .catch((e) => {
          console.log(e, "error generate workorder pm");
        });
    };
  
    const handleUpload = async(value) => {
      const doclink = masterAPI["serviceRequest"].uploadDoc;
      const uploadData = {
        serviceRequestId: viewId,
        ...value,
      };
      console.log(uploadData, "upload doc");
     await axiosPrivate
        .post(`${doclink}`, uploadData)
        .then((res) => {
          console.log(res);
          toast.success(res.data.message);
          // handleClose();
          getData()
        })
        .catch((e) => {
          console.log(e, "error doc wo ");
        });
    };
  
    return (
      <>
      {loading?<Loading/>:
      <Box sx={{ p: color.masterPageDialogPadding }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              // color: color.masterDialogTitleColor,
              // fontSize: color.masterDialogFontSize,
              fontSize: color.AssetTitlesize,
              fontWeight: color.sideFontWeightAsset,
            }}
          >
            {comDetail.code + "-" + comDetail.locationName}
          </Typography>
          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
        <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)" }} />
        <TabContext value={value}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TabList onChange={handleChange} aria-label="SR view">
              <Tab label="General" value="1" />
              {/* <Tab label="Document" value="2"  />
              <Tab label="Work Order" value="3"  /> */}
            </TabList>
          </Box>
          {/* <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)"}} /> */}
          <TabPanel value="1" sx={{ p: 0, pt: 1 }}>
            <Grid container spacing={"1rem"}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                 Location Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                    mb: color.TabDatamarginbottom,
                  }}
                >
                  {comDetail.locationName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Description
                </Typography>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                    mb: color.TabDatamarginbottom,
                  }}
                >
                  {comDetail.locationDesc === "" ? "---" : comDetail.locationDesc}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Parent Location Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                    mb: color.TabDatamarginbottom,
                  }}
                >
                  {comDetail.parentLocationName}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset
                </Typography>
                <Typography
                  sx={{
                    fontSize: color.TabDatafontsize,
                    mb: color.TabDatamarginbottom,
                  }}
                >
                  {comDetail.assetName}
                </Typography>
              </Grid> */}
    
              
            </Grid>
          </TabPanel>
          <TabPanel value="2" sx={{ p: 0, pt: 1 }}>
            <Box sx={{display:"flex",gap:"1rem",flexDirection:"column"}}>
            <Paper elevation={3} sx={{p:1}}>
              <Grid container>
                <Grid container item xs={12} justifyContent={"flex-end"}>
                  <IconButton disabled={comDetail.status!=="NEW"} sx={{display: isDialogOpen ? "none" : "default"}} onClick={()=>{setAdd(true)}}>
                  <Add/>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                 <NormalTable columns={columns} data={docData} />
                </Grid>
            </Grid>
            </Paper>
            <Paper elevation={3} sx={{p:1,display:add?"default":"none"}}>
            <DocUpload  handleUpload={handleUpload} handleCloseClick={()=>{setAdd(false)}}/>
            </Paper>
            </Box>
          </TabPanel>
          <TabPanel value="3" sx={{p:0,pt:1}}>
          <Grid  container item xs={12}>
                 <NormalTable columns={columnWO} data={woData} />
                </Grid>
          </TabPanel>
        </TabContext>
      </Box>
      }
      </>
    );
  };
  
  export default LocationView;
  