import axios, { AxiosRequestConfig } from "axios";
import { store } from "../Pages/ReduxStore/store";
import {toast} from "react-toastify"

 //export const baseURL="https://eamapi.s2tsoft.com/api"
// export const imageBaseURl="https://brommawebapp.ddns.net:8443/ojtapi/"
export const baseURL="https://demoapi.orienseam.com/api"
//export const baseURL="https://localhost:7182/api"

// export const siteInfo={
//   siteId:"2b143042-85e1-4edb-be93-ddfc87905275",
//   name:"Shah Alam"
// }

export const siteInfo={
  siteId:"e1bbe690-1ac0-41e4-9105-5e4449214082",
  name:"Combined Cycle Site"
}


export const axiosPrivate = axios.create({
  baseURL:baseURL,
});




  



axiosPrivate.interceptors.request.use(
  (config)=> {
    const storeData = store.getState();
    const  tokenData=storeData.auth.loginInfo.accessToken
    if(tokenData!==undefined){
      config.headers = config.headers || {};
      config.headers["Authorization"] =tokenData ;
    }
    return config;
  }
);

axiosPrivate.interceptors.response.use(async function(config){
   
  return config;
},(error) => {
  // console.log(error.response, "errrr");
  return new Promise( (resolve) => {

    const originalRequest = error.config;
    // console.log("checkkk");
    const refreshToken = store.getState().auth.loginInfo.refreshToken;
   
    if (error.response && error.response.data.StatusCode === 401 && refreshToken) {
      // console.log("res");
      originalRequest._retry = true;

      // body: formBody
      console.log(refreshToken)
      const response =  axios
        .post(`${baseURL}/users/refreshtoken?refreshtoken=${refreshToken}`)
        .then((res) => {
          console.log(res, "ccccc");
          return res;
        })
        .then((res) => {
          // console.log(res)
          store.dispatch({type:"updateAccessToken",payload:res.data.result});
          originalRequest.headers.authorization = res.data.result.accessToken;
          return axios(originalRequest);
        })
        .catch((e) => {
          if(e.response.data.StatusCode === 401){
            store.dispatch({type:"logout",payload:{}});
            console.log(e)
            toast.warning("Session Expired")
            // window.location.href = '/'
            //  throw e
            
            
          }
          return Promise.reject(e);
        });
      resolve(response);
    }
    // else{
    //   throw error;
    // }

    if (error.response) {
      throw error.response.data;
    }

    return Promise.reject(error);
  });
})




