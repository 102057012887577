import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Grid, Button, Dialog, Divider, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import NormalTable from "../../Component/NormalTable";
import { color } from "../../StyleData/Config";

const MTTRChart = ({ dashBoardDatamttXaxis, dashBoardDatamttYaxis, type, mttrwoList }) => {
  
  const [chartDialog, setChartDialog] = useState({ openComWOList: false, selectedCode: [], assetCode: "" });
  const filteredData = mttrwoList ? mttrwoList : [];
  console.log('mttrwoList:', mttrwoList);  
  const updatedData = filteredData.map((dataItem, index) => ({
    ...dataItem,  
    code: chartDialog.selectedCode[index] || '',  
  }));

  const columns = [
      {
        Header: "WO Code",
        accessor: "code",
        Cell: ({ cell }) => {
          // Match the full code and extract both the code and ID parts
          const codeMatch = cell.value.match(/Code:\s*([\w-]+)\s*- ID:\s*([a-f0-9-]+)\s*Name:\s*(.*)/);
          
          // Extract the code and the ID
          const displayCode = codeMatch ? codeMatch[1] : '';  // Code part
          const assetId = codeMatch ? codeMatch[2] : '';      // ID part
          const workOrderName = codeMatch ? codeMatch[3] : ''; // Name part
          const combinedCode = workOrderName ? `${displayCode}` : displayCode;
          return (
            <Link
              to={`/workorderview?id=${assetId}`}  // Pass only the ID in the URL
              style={{
                color: "#0d6efd",
                textDecorationLine: "underline",
                fontWeight: 800,
              }}
               target="_blank"
            rel="noopener noreferrer"
            >
       {combinedCode} 
            </Link>
          );
        },
      },
      {
        Header: "WO Name",
        accessor: "workOrderName",  
        Cell: ({ cell }) => {
          const workOrderName = cell.row.original.code.match(/Code:\s*([\w-]+)\s*- ID:\s*([a-f0-9-]+)\s*Name:\s*(.*)/)?.[3] || '';
          return workOrderName && workOrderName !== 'NULL' ? (
            <>{workOrderName}</>
          ) : null;
        },
      },
    ];

    const handleChartDialogOpen = (assetCode) => {
      console.log('handleChartDialogOpen triggered with assetCode:', assetCode);
      if (!mttrwoList || !assetCode) {
        console.error("mtbfwoList or assetCode is undefined")
        return;  
      }
      const selectedAsset = mttrwoList.find(item => item.assetCode === assetCode);
  
      if (selectedAsset) {
        const codes = selectedAsset.code.split(',').map(code => code.trim());
        setChartDialog({ openComWOList: true, selectedCode: codes, assetCode: assetCode });
      } else {
        console.error(`Asset with assetCode ${assetCode} not found in mtbfwoList`);
      }
    };
  
    const handleChartDialogClose = () => {
      setChartDialog({ openComWOList: false, selectedCode: [], assetCode: ""  });
    };
  
  return (
    <>
    <Chart
    onClick={(e, chartContext, config) => {
      const dataPointIndex = config?.dataPointIndex;
      const seriesIndex = config?.seriesIndex;
      // Check if dataPointIndex and seriesIndex are defined
      if (dataPointIndex !== undefined && seriesIndex !== undefined) {
        const assetCode = dashBoardDatamttYaxis[dataPointIndex];  // Access asset code based on the clicked data point
        console.log('Chart clicked with assetCode:', assetCode);  // Debugging line
        handleChartDialogOpen(assetCode);  // Pass assetCode to the handler
      } else {
        console.error('dataPointIndex or seriesIndex is undefined.');
      }
    }}
      height={
        type
          ? dashBoardDatamttXaxis.length
            ? `${32 * dashBoardDatamttYaxis.length + 200}px`
            : "100%"
          : 200
      }
      width={"100%"}
      options={{
        responsive: [
          {
            breakpoint: 768, // Change the breakpoint as needed
            options: {
              chart: {
                width: 600,
              },
            },
          },
        ],
        series: [
          {
            data: dashBoardDatamttXaxis,
          },
        ],
        title: {
          text: "Mean Time To Repair",
          style: {
            fontFamily: "Nunito Sans, sans-serif",
            color: "#2362B4",
          },
        },

        chart: {
          type: "bar",
          toolbar: {
            export: {
              csv: {
                filename: "MTTR",
              },
              svg: {
                filename: "MTTR",
              },
              png: {
                filename: "MTTR",
              },
            },
          },
          events: {
            dataPointSelection: function(event, chartContext, config) {
              const dataPointIndex = config?.dataPointIndex;
              const seriesIndex = config?.seriesIndex;
    
              if (dataPointIndex !== undefined && seriesIndex !== undefined) {
                const assetCode = dashBoardDatamttYaxis[dataPointIndex];
                console.log('DataPoint selected:', assetCode);  
                handleChartDialogOpen(assetCode);
              }
            }
          }
        },
        
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                  fontFamily: "Nunito Sans, sans-serif",
                },
              },
            },
          },
          noData: {
            text: "No Result",
            style: {
              fontSize: "1.5rem",
              color: "grey",
              fontFamily: "Nunito Sans, sans-serif",
            },
          },

          stroke: {
            width: 1,
            colors: ["#fff"],
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "Asset",
          },
          labels:{
            show:type
          }
        },
        xaxis: {
          title: {
            text: "Hours",
          },
          categories: dashBoardDatamttYaxis,
          // labels: {
          //   style: {
          //     fontFamily: "Nunito Sans, sans-serif",
          //   },
          // },
        },
      }}
      type="bar"
      series={[{ data: dashBoardDatamttXaxis }]}
    />
    <Dialog open={chartDialog.openComWOList} onClose={handleChartDialogClose}>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <Typography sx={{ color: color.masterPageTitleColor, fontSize: color.masterPageTitleFontSize, fontWeight: color.masterPageTitleFontWeight, mb: color.masterPageTitlemd, px: 2, py: 1 }}>
                Mean Time Before Failure Work Orders
              </Typography>
              <Typography sx={{ color: color.masterPageTitleColor, fontSize: color.masterPageTitleFontSize, fontWeight: color.masterPageTitleFontWeight, mb: color.masterPageTitlemd, px: 2, py: 1 }}>
              Asset Code: <span style={{ color: color.masterDialogTitleColor }}>{chartDialog.assetCode}</span>
              </Typography>
            </Box>
            <Box sx={{ px: 2, py: 1 }}>
              <NormalTable data={updatedData} columns={columns} filt={true} />
            </Box>
          </Grid>
  
          <Grid container item justifyContent={"flex-end"}>
            <Button onClick={handleChartDialogClose}>Close</Button>
          </Grid>
        </Grid>
      </Dialog>
      </>
  );
};

export default MTTRChart;
