import { Save } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Button,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { color } from "../../../../StyleData/Config";
import { axiosPrivate } from "../../../../axios/axios";
import { masterAPI } from "../../../Masters/dataConfig";
import { toast } from "react-toastify";

const ChangeStatusWO = ({ data,getData }) => {
  const [selectList, setSelectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false); 

  useEffect(() => {
    getDataList()
  }, []);

  const getDataList=async()=>{
    const selectLink = masterAPI["selectList"].woStatus;
   await axiosPrivate
      .get(`${selectLink}`)
      .then((res) => {
        console.log(res.data);
        setSelectList(res.data);
      })
      .catch((e) => {
        setSelectList([])
        console.log(e, "changeStatusAsset");
      });
  }


  const formik = useFormik({
    initialValues: {
      id: data.id,
      code:data.code,
      workOrderName: data.workOrderName,
      status: data.status,
      reasontoCancel:data.reasontoCancel
    },
    enableReinitialize: true,
    onSubmit: async(value) => {
      setLoading(true);
      const changeLink = masterAPI["workOrder"].changeStatus;
      // const putData={
      //   ...data,
      //   status:value.status
      // }
      // console.log(putData)
      console.log(value)
     await axiosPrivate
        .put(`${changeLink}`,value)
        .then((res) => {
          console.log(res);
          getData()

          toast.success("Status Updated successfully")
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "changeStatus update");
        });
    },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <Typography
          sx={{
            color: color.sideTitleColor,
            fontSize: color.fontSizeSide,
            fontWeight: color.sideWeightFont,
          }}
        >
          Change Status
        </Typography>
      </Box>

      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          p: 1,
        }}
      >
     <Box
    sx={{
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    }}
  >
    <Typography
      sx={{
        color: color.sideTitle2Color,
        fontSize: color.fontSizeSide,
        fontWeight: color.sideFontWeight,
      }}
    >
      Details
    </Typography>

    {showConfirm ? (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography sx={{color: "green"}}>Are you sure you want to save?</Typography>
        <Button
          onClick={() => {
            formik.handleSubmit();
            setShowConfirm(false); // Reset confirmation state
          }}
          sx={{ color: "grey", borderColor: "green" }}
          size="small"
          variant="outlined"
        >
          Yes
        </Button>
        <Button
          onClick={() => setShowConfirm(false)}
          sx={{ color: "grey", borderColor: "red" }}
          size="small"
          variant="outlined"
        >
          No
        </Button>
      </Box>
    ) : (
      <IconButton onClick={() => setShowConfirm(true)}>
        {loading ? <CircularProgress /> : <Save sx={{ color: "grey" }} />}
      </IconButton>
    )}
  </Box>
        <Divider />
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                WO Code
              </Typography>
              <TextField
                variant="standard"
                size={"small"}
                type="text"
                name="code"
                value={formik.values.code}
                placeholder="Enter WO Id"
                // fullWidth
                onChange={formik.handleChange}
                sx={{
                  width: "45%",
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                WO Name
              </Typography>
              <TextField
                variant="standard"
                size={"small"}
                type="text"
                name="workOrderName"
                value={formik.values.workOrderName}
                placeholder="Enter WO Name"
                onChange={formik.handleChange}
                // fullWidth
                sx={{
                  width: "45%",
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  mb: "0.5rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Status
              </Typography>

              <Select
                // label={"Select"}
                size={"small"}
                type="text"
                name="status"
                variant="standard"
                value={formik.values.status}
                onChange={formik.handleChange}
                // fullWidth
                sx={{ width: "45%", fontSize: "13px" }}
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                renderValue={
                  formik.values.status !== ""
                    ? undefined
                    : () => (
                        <Typography
                          sx={{
                            color: color.placeholderColor,
                            fontSize: "13px",
                          }}
                        >
                          Select
                        </Typography>
                      )
                }
              >
                {selectList.map((id, i) => (
                  <MenuItem value={id.value}>{id.text}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{display:formik.values.status==="CAN"?"default":"none"}}>
              <Typography
                sx={{
                  mb: "0.7rem",
                  fontSize: color.sideLabelFont,
                  color: color.sideLabelColor,
                }}
              >
                Feedback
              </Typography>
              <TextField
                variant="standard"
                size={"small"}
                type="text"
                name="reasontoCancel"
                multiline
                minRows={1}
                maxRows={10}
                value={formik.values.reasontoCancel}
                placeholder="Enter Reason"
                onChange={formik.handleChange}
                // fullWidth
                sx={{
                  width: "45%",
                  ".MuiInputBase-input": {
                    // letterSpacing: "0.2rem",
                    fontSize: "13px",

                    "&::placeholder": {
                      // color: "green",
                      opacity: 1,
                      color: color.placeholderColor,
                      fontSize: "13px",
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ChangeStatusWO;
